import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class MentionsLegales extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Mentions légales du site<br />Chris Happy Pink</h2>
                                    {/*<p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
                                </div>
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p className="mb-2">
                                            Conformément aux dispositions des articles 6-III et 19 de la Loi pour la Confiance dans
                                            l'économie numérique n°2004-575 du 21 juin 2004 (L.C.E.N), il est porté à la connaissance
                                            des utilisateurs et visiteurs du site Chris Happy Pink les présentes mentions légales.
                                        </p>
                                        <p className="mb-2">
                                            Le site Chris Happy Pink est accessible à l'adresse suivante : www.chrishappypink.fr (ci-après
                                            "le Site").
                                        </p>
                                        <p className="mb-2">
                                            L'accès et l'utilisation du Site sont soumis aux présentes "Mentions légales" détaillées ci-après
                                            ainsi qu'aux lois et/ou règlements applicables.
                                        </p>
                                        <p className="mb-2">
                                            L'utilisateur du Site désigne toute personne qui se connecte, utilise ou accède au Site (ci-après "l'Utilisateur").
                                        </p>
                                        <p>
                                            L'Utilisateur accepte intégralement et sans réserve toutes les dispositions des présentes
                                            Mentions Légales.
                                        </p>
                                        <h5 className="title">EDITEUR DU SITE</h5>
                                        <p>
                                            Chris Happy Pink, Christelle Frigo 5 Bis, Chemin du Prieuré 95380 Epiais-lès-Louvres
                                        </p>
                                        <h5 className="title">SIREN</h5>
                                        <p>
                                            831 370 705
                                        </p>
                                        <h5 className="title">DIRECTEUR DE PUBLICATION</h5>
                                        <h5 className="title">HÉBERGEUR DU SITE</h5>
                                        Heroku
                                        <h5 className="title">DISPOSITIF DE MEDIATION</h5>
                                        <p className="mb-2">
                                            (conformément à l’article L.612-1 du code de la consommation). SL
                                            Médiation SASU, 38 rue Vernier 75017 Paris RCS Paris : 880 510417
                                        </p>
                                        <p className="mb-2">
                                            Les présentes Mentions Légales sont régies par la loi française.
                                        </p>
                                        <p className="mb-2">
                                            En cas de différend, et à défaut d’accord amiable, l’Utilisateur pourra saisir le médiateur
                                            compétent ciavant désigné.
                                        </p>
                                        <p className="mb-2">
                                            En cas d'échec de la médiation, le litige sera porté devant les tribunaux français
                                            conformément aux règles de compétence en vigueur.
                                        </p>
                                        <p>
                                            Pour tout signalement de contenus ou d'activités illicites, tout utilisateur du Site pourra
                                            contacter l'Editeur aux coordonnées mentionnées dans les présentes, ou par courrier
                                            recommandé avec accusé de réception auprès de celui-ci, aux coordonnées précisées ci-
                                            dessus.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <Link className="btn-default" to="/"><span>Retour</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default MentionsLegales;