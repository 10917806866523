import React, {Component} from "react";
import {FiCheck} from "react-icons/fi";
import {Link} from 'react-router-dom';

class Stretching extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Stretching</h2>
                                </div>
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>
                                            Vous n'aimez pas le sport, mais vous sentez que votre corps a besoin de bouger ? Ce cours est fait pour vous !
                                            Nous allons pendant une séance, étirer les muscles en douceur dans des postures statiques ou en mouvement.
                                        </p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/bg/bg-image-31.jpg" alt="Blog Images"/>
                                        </div>
                                        <p className="mt--40">
                                            Beaucoup de bénéfices pour vous :
                                        </p>
                                        <ul className="list-style--1 mt--20">
                                            <li><FiCheck /> diminitions des douleurs au niveau du dos ;</li>
                                            <li><FiCheck /> relâchement des tensions musculaires ;</li>
                                            <li><FiCheck /> meilleure conscience de son corps ;</li>
                                            <li><FiCheck /> correction de la posture ;</li>
                                            <li><FiCheck /> tonification ;</li>
                                            <li><FiCheck /> amélioration de la souplesse ;</li>
                                            <li><FiCheck /> silhouette affinée, etc.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <Link className="btn-default" to="/"><span>Retour</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Stretching;