import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Yoga extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Yoga</h2>
                                    {/*<p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
                                </div>
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>
                                            Retrouver de la mobilité, de la souplesse, respirer dans votre corps et votre tête.
                                            Eloigner le stress, retrouver un sommeil serein, s'ancrer dans la vie, voilà quelques-uns des
                                            bénéfices du yoga.
                                        </p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/bg/bg-image-29.jpg" alt="Blog Images"/>
                                        </div>
                                        <p className="mt--40">
                                            Je vous propose plusieurs sortes de yoga :
                                        </p>
                                        <h5>Happy Pink Yoga®</h5>
                                        <p>
                                            Yoga spécial cancer du sein. Un yoga doux, adapté et thémé en fonction des besoins spécifiques des "Happy Pink Girls" (ou boys).
                                            Pour réapprivoiser son corps avant, pendant et après la maladie. Retrouver la joie d'habiter ce nouveau corps !
                                        </p>
                                        <h5 className="title">Yoga doux</h5>
                                        <p>
                                            Nous enchaînerons des mouvements et postures statiques que nous allons maintenir. Le yoga doux pourra aussi se faire sur une chaise.
                                        </p>
                                        <h5>Yoga de la femme</h5>
                                        <p>
                                            Technique de yoga simple et accessible à tous les niveaux. Il combine le yoga des hormones, le yoga du visage, le Yin yoga, des postures de yoga,
                                            des Mudras, des points d'acupression et des techniques de respiration et de relaxation.
                                        </p>
                                        <h5>Yoga Dance</h5>
                                        <p>
                                            Après un échauffement en yoga pendant lequel nous avons un enchaînement de postures de yoga, nous allons mettre ces mêmes postures
                                            en mouvement et surtout en musique pour trouver une fluidité et ainsi réaliser une chorégraphie.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <Link className="btn-default" to="/"><span>Retour</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Yoga;