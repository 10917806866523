import React, {Component} from "react";
import {Link} from 'react-router-dom';

class Cgu extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Conditions Générales d'Utilisation</h2>
                                    {/*<p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
                                </div>
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h5 className="title">En présentiel et en Visio</h5>
                                        <p>
                                            <ul>
                                                <li>Votre abonnement est valable à vie. Néanmoins, il sera désactivé après 5 ans d'inactivité.</li>
                                                <li>Un certificat médical de moins de 3 ans est à fournir le plus rapidement possible en début de saison (valable 3 ans) à me remettre en main propre
                                                    ou par mail kitoun.cf@gmail.com.</li>
                                            </ul>
                                        </p>
                                        <h5 className="title">Présentiel</h5>
                                        <p>
                                            <ul>
                                                <li>La somme de 10 € de frais d'adhésion à l'association vous sera demandée chaque nouvelle saison (en espèce lors du 1er cours).</li>
                                                <li>Les cours doivent être réservés au moins 48h à l'avance sur le site. A défaut, me prévenir via Whatsap, SMS.</li>
                                                <li>Vous avez la possibilité d'annuler votre participation jusqu'à 24h avant le début du cours (sur le site).</li>
                                            </ul>
                                        </p>
                                        <h5 className="title">Visio</h5>
                                        <p>
                                            <ul>
                                                <li>Les cours doivent être réservés au moins 48h à l'avance.</li>
                                                <li>Vous avez la possibilité d'annuler votre participation jusqu'à 24h avant le début du cours.</li>
                                                <li>Si vous ne pouvez pas participer à un cours que vous avez réservé, un "Replay" vous sera automatiquement envoyé et votre cours sera décompté de votre abonnement.</li>
                                            </ul>
                                        </p>
                                        <h5 className="title">Replay</h5>
                                        <p>
                                            <ul>
                                                <li>A la fin de chaque cycle, vous recevrez 3 replays (yoga, pilates, yoga de la femme).</li>
                                                <li>Si vous voulez d'autres replays vous pouvez les commander sur le site.</li>
                                            </ul>
                                        </p>
                                        <h5 className="title">Matériel</h5>
                                        <p>
                                            <ul>
                                                <li><u>Pilates</u> : tapis, tenue confortable, pieds nus, bouteille d'eau (éventuellement : ballon paille, pad pour protéger les genoux).</li>
                                                <li><u>Yoga</u> : tapis, tenue confortable, pieds nus, bouteille d'eau, 2 briques de yoga et une sangle (éventuellement coussin, pad pour protéger les genoux, couverture...).</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <Link className="btn-default" to="/"><span>Retour</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Cgu;