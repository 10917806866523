import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Pilates extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Pilates</h2>
                                </div>
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>
                                            Mal de dos ? Sensation de raideur dans les articulations ? Sangle abdominale disparue ???
                                        </p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/bg/bg-image-32-0.jpg" alt="Blog Images"/>
                                        </div>
                                        <p className="mt--40">
                                            Grâce à des postures simples et précises, nous allons exécuter une série de mouvements coordonnés
                                            à la respiration. Cette gymnastique douce travaille tous les muscles en profondeur. Bien-être garanti !
                                        </p>
                                        <p>
                                            Je vous propose également le Rose Pilates conçu pour les femmes opérée d' un cancer du sein.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <Link className="btn-default" to="/"><span>Retour</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Pilates;