import React, {Component} from "react";
import {Link} from 'react-router-dom';

class Cgv extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--50 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Conditions Générales de Vente<br />Chris Happy Pink</h2>
                                    {/*<p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
                                </div>
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>
                                            Les présentes conditions de vente sont conclues : d'une part, CHRISTELLE FRIGO, ayant pour numéro de SIREN 831 370 705,
                                            ci-après dénommée "CHRIS HAPPY PINK" ; et d'autre part, par toute personne physique ou morale navigant et
                                            souhaitant procéder à un achat mis en vente sur le site Internet : www.chrishappypink.fr dénommée ci-après alternativement "l'acheteur".
                                        </p>
                                        <h5 className="title">Objet</h5>
                                        <p className="mb-2">
                                            Les présentes conditions de vente visent à définir les relations
                                            contractuelles entre CHRIS HAPPY PINK et l'acheteur et les conditions
                                            applicables à tout achat effectué par le biais du site www.chrishappypink.fr (ci-
                                            après "le Site").
                                        </p>
                                        <p className="mb-2">
                                            L'acquisition d'un bien ou d'un service à travers le présent site implique une
                                            acceptation sans réserve par l'acheteur des présentes conditions de vente.
                                        </p>
                                        <p className="mb-2">
                                            Ces conditions de vente prévaudront sur toutes autres conditions générales ou
                                            particulières non expressément agréées par Chris Happy Pink.
                                        </p>
                                        <p className="mb-2">
                                            Chris happy pink se réserve le droit de pouvoir modifier ses conditions de vente
                                            à tout moment. Dans ce cas, les conditions applicables seront celles en vigueur à
                                            la date de la commande par l'acheteur.
                                        </p>
                                        <p>
                                            "L’élève" représente la personne qui exécute le service payé proposé par Chris
                                            Happy Pink, étant acheteur ou bénéficiaire d’un service du site qui lui aurait été
                                            offert.
                                        </p>
                                        <h5 className="title">Biens et services proposés</h5>
                                        <p className="mb-2">
                                            Les biens et services sont ceux proposés à l’achat figurant sur le Site. Il s’agit de
                                            cours, ateliers et/ou stages de Yoga ainsi que tout produits dérivés et autres
                                            services en relation avec le Yoga, le bien-être, le développement personnel,
                                            accessibles sur le Site (ci-après, le "service" ou "les services").
                                        </p>
                                        <p>
                                            Les services peuvent être acheté ponctuellement par unité, en plusieurs unités
                                            ou encore avec une formule d’abonnement comprenant plusieurs services pour
                                            une durée délimitée
                                        </p>
                                        <h5 className="title">Tarifs</h5>
                                        <p className="mb-2">
                                            Les prix des services sont accessibles dans la grille tarifaire sur le Site, à la
                                            rubrique "Tarifs" et sont des prix HT en euro, la TVA étant non applicable,
                                            conformément à l'article 293 B du CGI.
                                        </p>
                                        <p>
                                            Chris Happy Pink se réserve le droit de modifier ses prix à tout moment, étant
                                            toutefois entendu que le prix figurant sur le site le jour de la commande sera le
                                            seul applicable à l'acheteur.
                                        </p>
                                        <h5 className="title">Modalités de paiement</h5>
                                        <p className="mb-2">
                                            Pour tout service nécessitant la présence physique d'un professeur de Chris
                                            Happy pInk (cours ou atelier, événement de Yoga auprès d'entreprise, de
                                            particulier, d'association ou tout autre service présentiel), le paiement
                                            s'effectuera par espèce, chèque ou virement bancaire à échéance de la facture
                                            émise par Chris Happy Pink.
                                        </p>
                                        <p>
                                            Chris Happy Pink se réserve le droit de modifier ses prix à tout moment, étant
                                            toutefois entendu que le prix figurant sur le site le jour de la commande sera le
                                            seul applicable à l'acheteur.
                                        </p>
                                        <h5 className="title">Conditions et modalités de réservation / achat de services</h5>
                                        <p className="mb-2">
                                            Toute réservation/achat d’un service peut être effectuée dès que ledit service
                                            est proposé à l’achat sur le Site.
                                        </p>
                                        <p className="mb-2">
                                            Aucune réservation ne pourra être effectuée lors de l’exécution du service ou
                                            postérieurement à celui-ci.
                                        </p>
                                        <p className="mb-2">
                                            L’acheteur, qui souhaite réserver/acheter un service en ligne doit
                                            obligatoirement :
                                            <ul>
                                                <li>Sélectionner la date où le service qu’il souhaite acheter sera exécuté sur le planning de réservation en ligne.</li>
                                                <li>Sélectionner la formule d’achat qui lui convient.</li>
                                                <li>S’identifier, ou si c’est sa première réservation, créer un compte sur la plateforme Danceschool (logiciel avec lequel la réservation en ligne
                                                    s’effectue), correspondant au planning en ligne, en remplissant son nom, prénom et son e-mail et choisir un mot de passe, accepter les termes et
                                                    conditions de l’achat ??? CGU ???</li>
                                                <li>Recevoir un email de confirmation de sa réservation/achat.</li>
                                                <li>Procéder au paiement de sa commande par virement bancaire dans les
                                                    conditions prévues dans les instructions dudit email; L’acheteur ne recevra
                                                    les codes d’accès au service en ligne qu’à la condition d’avoir
                                                    effectivement procédé au paiement du service.<br />
                                                    Ces codes d’accès seront transmis par email de quelques heures à quelques
                                                    minutes avant l’exécution du service en ligne.<br />
                                                    Les différentes formules d’achat proposés peuvent être sélectionnées sur
                                                    le planing de réservation en ligne, après avoir séléctionné la date de
                                                    d’éxécution du service choisi.<br />
                                                    2 types d'abonnement sont proposés ; en visio ou en ligne ; cours à l'unité,
                                                    cours d'essai, carte de 5 cours, carte de 10 cours, et engage l’acheteur
                                                    financièrement sur toute la durée choisie. La durée de validité de l'achat
                                                    est illimitée.<br />
                                                    L’acheteur règle la totalité du montant de l’abonnement sélectionné dès
                                                    l’achat de celui-ci.</li>
                                                <li>Aucun remboursement ne pourra être effectué.</li>
                                                <li>Pour les cours en présentiel, 10€ d'adhésion à l'association "Happy Pink"
                                                    sera demandé chaque mois de septembre.</li>
                                            </ul>
                                        </p>
                                        <p className="mb-2">
                                            La confirmation de toute réservation ou achat entraîne acceptation totale des
                                            présentes conditions de vente, la reconnaissance d’en avoir parfaite
                                            connaissance et la renonciation à se prévaloir de ses propres conditions d’achat
                                            ou d’autres conditions.
                                        </p>
                                        <p className="mb-2">
                                            L’ensemble des données fournies et la confirmation enregistrée vaudront preuve
                                            de la transaction.
                                        </p>
                                        <p className="mb-2">
                                            La confirmation vaudra signature et acceptation des opérations effectuées
                                            communiquera par courrier électronique confirmation de la commande
                                            enregistrée.
                                        </p>
                                        <p>
                                            L’acheteur reconnaît avoir pris connaissance du planning ainsi que des conditions
                                            de paiement. Pour tout problème avec votre commande, vous pouvez contacter
                                            Chris Happy Pink par email rebrique "Contact".
                                        </p>
                                        <h5 className="title">Conditions d'annulation</h5>
                                        <p className="mb-2">
                                            Tout acheteur peut procéder à l’annulation de son achat avant l’exécution du
                                            service 24h avant le début de celui-ci.
                                            Passé ce délai un replay lui sera envoyé et le cours sera décompté de sa carte.
                                        </p>
                                        <p className="mb-2">
                                            Réservation obligatoire 48h à l'avance.
                                        </p>
                                        <p className="mb-2">
                                            Le paiement est non remboursable.
                                        </p>
                                        <p>
                                            L’acheteur reconnaît avoir pris connaissance du planning ainsi que des
                                            conditions de paiement.
                                        </p>
                                        <h5 className="title">Politique de retour</h5>
                                        <p className="mb-2">
                                            Conformément aux articles L.121-20 et suivants du Code de la consommation,
                                            vous bénéficiez d’un délai de sept (7) jours pour exercer votre droit de
                                            rétractation sans avoir à justifier de motifs ni à payer de pénalités. Le délai de
                                            sept jours court à compter de la date de paiement. Vous pouvez exercer ce droit
                                            sur toutes les séries achetées. Pour annuler votre réservation ou achat dans le
                                            délai de rétractation de 7 jours ouvrés, contactez-nous à partir de la rubrique "Contact".
                                        </p>
                                        <p className="mb-2">
                                            En revanche, conformément à l’article L221-28 du code de la Consommation,
                                            tout service acheté dont l’exécution aurait commencé ne pourra donner lieu à
                                            remboursement, l’acheteur renonçant à son droit de rétractation dans les
                                            conditions légales prévues, ce que l’acheteur reconnaît expressément.
                                        </p>
                                        <p className="mb-2">
                                            CAS DE FORCE MAJEURE : Chris Happy Pink est libéré de ses obligations
                                            contractuelles en cas de survenance d’un événement de force majeure, ou de
                                            tout fait imputable à un tiers, ou toute autre circonstance ayant une cause
                                            extérieure et/ou indépendante du fait personnel de Chris Happy Pink
                                            l’empêchant directement ou indirectement d’exécuter normalement ses
                                            obligations contractuelles.
                                        </p>
                                        <p className="mb-2">
                                            Les cas de force majeure comprendront, notamment, outre les cas reconnus par
                                            la jurisprudence, toute catastrophe naturelle, tout acte de guerre, atteintes à
                                            l’ordre public, épidémies, incendies, inondations et autres désastres, tout acte
                                            gouvernemental, toute grève sous quelque forme que ce soit et tout
                                            dysfonctionnement d’internet ou des réseaux. Dans l’hypothèse où un cas de
                                            force majeure empêche, retarde ou affecte l’exécution d’une obligation, tout
                                            retard ou défaut d’exécution de ses obligations ne peut donner lieu à des
                                            dommages et intérêts.
                                        </p>
                                        <p>
                                            Chris Happy Pink ne pourrait en aucun cas être tenu responsable de problèmes
                                            techniques limitant l’accès aux services ou aux dommages causés au matériel ou
                                            à l’équipement de l’acheteur.
                                        </p>
                                        <h5 className="title">Engagements-obligations l'acheteur</h5>
                                        <h6>Comportement</h6>
                                        <p>
                                            L’élève reconnaît que sa participation aux services achetés (cours, stages,
                                            ateliers et formations de yoga ou autres) demande un investissement personnel
                                            profond, une bonne condition physique. Tout comportement portant atteinte à
                                            sa propre sécurité, celle d’autrui (notamment consommation de drogues, alcool
                                            etc.) ou qui empêcherait le bon déroulement des services (cours, stages et/ou
                                            ateliers), sera sanctionné par l’exclusion définitive de l’élève sans que ce
                                            dernier ne puisse prétendre au remboursement des sommes versées à Chris
                                            Happy Pink, ni ne puisse réserver de services dans le futur.
                                        </p>
                                        <h6>Ponctualité</h6>
                                        <p className="mb-2">
                                            Les cours démarrent et terminent à l’heure. Il est demandé à l’élève de
                                            respecter les horaires de début et de fin du cours afin de ne pas gêner son bon
                                            déroulement.
                                        </p>
                                        <p className="mb-2">
                                            Média: Tout enregistrement, copie, reproduction sur quel support qu’il soit
                                            (vidéo, sonore ou autre etc.) sans l’autorisation explicite écrite de Chris Happy
                                            PInk est strictement interdit.
                                        </p>
                                        <p>
                                            Tout exploitation d’un tel enregistrement pourra entrainer la saisie des autorités
                                            de police et/ou des autorités judiciaires suite à une mise en demeure de cesser
                                            laissée sans exécution dans un délai de 7 (sept) jours à réception de ladite mise
                                            en demeure.
                                        </p>
                                        <h5 className="title">Responsabilité et garantie</h5>
                                        <p>
                                            N’étant pas en mesure d’évaluer les capacités physiques ou mentales de l’élève,
                                            ni de contrôler les informations transmises par celui-ci lors de sa réservation aux
                                            services (stages, cours en ligne ou présentiels, ateliers et formations et autres),
                                            Chris Happy Pink recommande à l’élève de consulter son médecin en vue
                                            d’obtenir un certificat d’aptitude à la pratique du yoga et/ ou du Pilates.
                                            De plus, n’étant pas en mesure de corriger tactilement l’élève lors des services
                                            dispensés en visio, Chris Happy Pink ne pourra en aucun cas être tenue
                                            responsable de blessure ou d’un accident survenu à l’élève à cet occasion.
                                            Enfin, Chris Happy Pink ne pourra en aucun cas être tenue responsable de
                                            blessure ou d’un accident survenu à l’un des élèves durant tout cours, stages,
                                            ateliers, formations ou tout autre service proposé, sauf à ce que la preuve d’une
                                            faute commise par Chris Happy PInk ou par l’un de ses prestataires soit établie
                                            en justice. Chris Happy Pink ne saurait être responsable des dommages
                                            directs ou indirects subis par l’élève lorsque le comportement de ce dernier en
                                            est à l’origine ou a concouru au dommage qu’il prétend subir.
                                        </p>
                                        <h5 className="title">Droit applicable</h5>
                                        <p>
                                            Les présentes Conditions Générales sont régies, interprétées et appliquées
                                            conformément au droit français, la langue d’interprétation étant la langue
                                            française en cas de contestation sur la signification d’un terme ou d’une
                                            disposition des présentes. RÉCLAMATIONS–INFORMATIONS–NOTIFICATIONS Toute
                                            réclamation, demande complémentaire d’information ou notification prévue aux
                                            présentes Conditions Générales devra être adressée par email à l’adresse
                                            électronique suivante: kitoun.cf@gmail.com.
                                        </p>
                                        <h5 className="title">Conditions générales d'utilisation</h5>
                                        <h6>Article 1 - Acceptation des conditions générales d'utilisation</h6>
                                        <p className="mb-2">
                                            L'Utilisateur est présumé connaître et accepter, sans réserve, l'ensemble des
                                            conditions generals d’utilisation (“CGU”) du seul fait de sa connexion au Site,
                                            quels que soient les terminaux numériques de réception utilisés.
                                        </p>
                                        <p className="mb-2">
                                            Les CGU s'appliquent concomitamment aux Conditions Particulières à la Charte
                                            Données Personnelles et Cookies et à toute déclinaison ou extension du site sur
                                            les réseaux sociaux et/ou communautaires existants ou à venir. L'Utilisateur est
                                            invité à ne pas se connecter au Site, ni utiliser les services s'il n'accepte pas tout
                                            ou partie des documents visés ci-dessus.
                                        </p>
                                        <p className="mb-2">
                                            L’éditeur peut à tout moment modifier les termes des CGU.
                                        </p>
                                        <p>
                                            L’Utilisateur est expressément informé que la version en vigueur est celle qui est
                                            mise en ligne à la date de son accès, ce que l’Utilisateur reconnaît et accepte
                                            sans restriction, s’engageant à s’y referrer systématiquement lors de chaque
                                            cours.
                                        </p>
                                        <h6>Article 2 - Inscription aux services</h6>
                                        <p className="mb-2">
                                            L’éditeur met à la disposition des
                                            utilisateurs les différents Services accessibles par le biais d’un planning de
                                            reservation en ligne. L’Utilisateur est informé que l’éditeur se réserve le droit
                                            de modifier ou de supprimer, à tout moment, sans préavis et à son entière
                                            discrétion, tout ou partie des services.
                                        </p>
                                        <p>
                                            L’éditeur se réserve ainsi le droit de suspendre l'accès aux services en cas de
                                            perte, vol et/ou utilisation des données de l'utilisateur dont ce dernier l'a
                                            informé, en cas d'utilisation non conforme aux dispositions des présentes.
                                            La responsabilité de l’éditeur ne peut être engagée en raison de ces
                                            interruptions et des conséquences qui peuvent en découler pour l’utilisateur.
                                        </p>
                                        <h6>Article 3 - Accès aux services et suspension des services</h6>
                                        <p className="mb-2">
                                            <b>Disponibilité du service</b>
                                        </p>
                                        <p className="mb-2">
                                            L’éditeur s’engage à faire ses meilleurs efforts pour assurer que l'accès et le
                                            fonctionnement du Site soit assuré 24 heures sur 24 et 7 jours sur 7 sans
                                            interruption.
                                        </p>
                                        <p className="mb-2">
                                            Toutefois, l’éditeur ne peut voir sa responsabilité recherchée en cas
                                            d'indisponibilité temporaire du Site et des Services (i) en cas de maintenance du
                                            Site ou serveur sur lesquel il est hébergé, (ii) en cas de survenance d'événements
                                            extérieurs à sa volonté et à son contrôle et (iii) en raison de cas de force
                                            majeure.
                                        </p>
                                        <p className="mb-2">
                                            Toutefois, l’éditeur ne peut voir sa responsabilité recherchée en cas
                                            d'indisponibilité temporaire du Site et des Services (i) en cas de maintenance du
                                            Site ou serveur sur lesquel il est hébergé, (ii) en cas de survenance d'événements
                                            extérieurs à sa volonté et à son contrôle et (iii) en raison de cas de force
                                            majeure.
                                        </p>
                                        <p>
                                            L’éditeur informera au préalable l'Utilisateur de l'indisponibilité si l'événement à
                                            l'origine de l'indisponibilité est prévisible.
                                        </p>
                                        <p className="mb-2">
                                            <b>Suspension de l'accès</b>
                                        </p>
                                        <p className="mb-2">
                                            L’éditeur se réserve le droit de suspendre l'accès aux Services (i) en cas de
                                            perte, vol et/ouvutilisation des données d'identification de l'Utilisateur dont ce
                                            dernier l'a informé, (ii) en cas d'utilisation non conforme aux dispositions des
                                            présentes, (iii) en cas d'opérations de maintenance.
                                        </p>
                                        <p>
                                            La responsabilité de l’éditeur ne peut être engagée en raison de ces
                                            interruptions et des conséquences qui peuvent en découler pour l’Utilisateur.
                                        </p>
                                        <h6>Article 4 - Engagement des utilisateurs</h6>
                                        <p className="mb-2">
                                            L'Utilisateur est informé que l'ensemble des lois et règlements en vigueur sont
                                            applicables sur Internet.
                                        </p>
                                        <p className="mb-2">
                                            Comportement général des utilisateurs : Lors de son inscription et à l’occasion
                                            de toute utilisation des différents Services auxquels le compte donne accès, tout
                                            Utilisateur s’engage tant à l’égard de l’éditeur que des autres Utilisateurs
                                            inscrits et de manière générale de tout tiers, à :
                                            <ul>
                                                <li>fournir des données exactes, actualisées, vraies, complètes ;</li>
                                                <li>ne pas fournir de données erronées, obsolètes ;</li>
                                                <li>ne pas usurper, en tout ou partie, l’identité, le titre ou la qualité d’un tiers ;</li>
                                                <li>respecter la gestion des accès et en particulier ne pas masquer sa
                                                    veritable identité en se connectant sous les paramètres personnels d'un
                                                    autre Utilisateur ;</li>
                                                <li>garder confidentiels ses Identifiants et ne pas les dévoiler à des tiers ;</li>
                                                <li>avertir l’éditeur de tous les dysfonctionnements logiques et techniques
                                                    qu’il pourrait constater et toutes les anomalies qu’il pourrait découvrir ;</li>
                                                <li>en cas de non-respect des principes énoncés ci-dessus, l’éditeur se réserve
                                                    la possibilité de suspendre ou refuser l’accès, sans préavis ni indemnité, de
                                                    façon temporaire ou définitive, à tout ou partie des services.</li>
                                            </ul>
                                        </p>
                                        <h6>Article 5 - Propriété intellectuelle</h6>
                                        <p className="mb-2">
                                            Chris Happy pink est titulaire du nom de domaine : chrishappypink.com
                                        </p>
                                        <p className="mb-2">
                                            Le Site ainsi que les éléments qui le composent sont la propriété exclusive de
                                            Chris Happy Pink et de leurs propriétaires respectifs, seuls habilités à utiliser les
                                            droits de propriété intellectuelle et droits de la personnalité y afférents,
                                            notamment marques, modèles, droits d'auteurs et droit à l'image, à titre
                                            originaire ou par l'effet d'une licence ou d'une autorisation expresse. L'ensemble
                                            des éléments du Site édité par Chris Happy PInk (notamment la structure, tous
                                            les textes, photos, vidéos, données, affiches, logos, marques et autres éléments
                                            reproduits) est réservé et protégé par le droit de la propriété intellectuelle,
                                            notamment les droits d'auteur, droits voisins, droits des marques.
                                        </p>
                                        <p className="mb-2">
                                            Le contenu du Site et l’utilisation des Services, protégés par la loi et notamment
                                            les dispositions du Code de Propriété Intellectuelle, sont exclusivement destinés
                                            à l’information et l’usage personnel de l’Utilisateur qui bénéficie d’un droit
                                            d’usage privé, non collectif et non exclusif.
                                        </p>
                                        <p className="mb-2">
                                            L'usage de tout ou partie du site, notamment par téléchargement, reproduction,
                                            transmission, représentation ou diffusion à d'autres fins que pour l'usage
                                            personnel et privé dans un but non commercial de l'internaute est strictement
                                            interdit.
                                        </p>
                                        <p>
                                            La violation de ces dispositions soumet son auteur aux sanctions prévues au titre
                                            notamment de la contrefaçon de droits d'auteur, de droit des marques. Sauf
                                            autorisation expresse et préalable de l’éditeur, sont interdites toutes
                                            reproduction, représentation et utilisation autres que celles visées ci-dessus et
                                            notamment : toute adaptation, mise à disposition du public à sa demande ou
                                            non, distribution, rediffusion sous quelque forme que ce soit, mise en réseau,
                                            communication publique de tout ou partie des oeuvres, prestations, marques et
                                            tous éléments protégés ou susceptibles de protection par le droit de la propriété
                                            intellectuelle reproduits sur le Site; toute extraction ou réutilisation, y compris
                                            à des fins privées, d’une partie substantielle du contenu des bases de données
                                            constituées par le Site; (toute extraction ou réutilisation, répétée et
                                            systématique, y compris à des fins privées, d’une partie même non substantielle
                                            du contenu des bases de données constituées par le Site; tout lien, accès,
                                            modification, ajout, suppression qui porte sur le système de traitement
                                            automatisé de l’édition en ligne et modifie les conditions de publication ou la
                                            politique éditoriale.
                                        </p>
                                        <h6>Article 6 - Charte des données personnelles et cookies</h6>
                                        <p className="mb-2">
                                            <b>Protection des données</b>
                                        </p>
                                        <p className="mb-2">
                                            Lors de l’utilisation du Site par l’Utilisateur (l’Utilisateur étant défini dans les
                                            mentions légales du Site), des données sont susceptibles d’être collectées et
                                            traitées avec l’accord exprès de l’Utilisateur en vue de permettre l’accès aux
                                            services proposés par le Site (ci-après les "Services").
                                        </p>
                                        <p className="mb-2">
                                            Quelles sont les données collectées ?
                                            <ul>
                                                <li>Les données que l'Utilisateur renseigne lorsqu'il effectue des achats par le
                                                    biais du Site telles que notamment son adresse électronique, son nom et
                                                    prénom et les renseignements relatifs à son moyen de paiement, c’est à
                                                    dire son numéro de téléphone.</li>
                                                <li>Les données bancaires ne sont ni transmises ni collectées par Chris Happy Pink.</li>
                                                <li>Les données relatives à la navigation : désignent les données que l’Editeur
                                                    collecte lors de la navigation de l’Utilisateur sur le Site telles que
                                                    notamment la date, l’heure de la connexion et/ou navigation, le type de
                                                    navigateur, la langue du navigateur, son adresse IP. L'ensemble des ces
                                                    données seront collectivement visées ci-après les "Données".</li>
                                            </ul>
                                        </p>
                                        <p className="mb-2">
                                            Pourquoi l’éditeur collecte-t-il les données de l'Utilisateur ?<br />
                                            Les Données recueillies dans le cadre de l’utilisation du Site font l’objet
                                            d’un traitement dans le but de répondre notamment aux finalités suivantes :
                                            <ul>
                                                <li>La gestion des accès à certains Services ainsi que leur utilisation ;</li>
                                                <li>La constitution de fichiers de membres inscrits aux Services ;</li>
                                                <li>Lors de la collecte des Données, l’Utilisateur sera informé si certaines
                                                    Données doivent être obligatoirement renseignées ou si elles sont
                                                    facultatives.</li>
                                                <li>Les Données identifiées par un astérisque au sein du formulaire
                                                    d’inscription sont obligatoires.</li>
                                                <li>A défaut, l’accès aux Services et leur utilisation par l’Utilisateur sera
                                                    impossible.</li>
                                            </ul>
                                        </p>
                                        <p className="mb-2">
                                            Destinataires des Données collectées
                                        </p>
                                        <p className="mb-2">
                                            La base de Données constituée à l’occasion de l’inscription aux Services est
                                            strictement confidentielle.
                                        </p>
                                        <p className="mb-2">
                                            L’éditeur s’engage à prendre toutes les précautions utiles, mesures
                                            organisationnelles et techniques appropriées pour préserver la sécurité,
                                            l’intégrité et la confidentialité des Données et notamment, empêcher qu’elles
                                            soient déformées, endommagées ou que des tiers non autorisés y aient accès.
                                        </p>
                                        <p className="mb-2">
                                            Données transférées aux autorités et/ou organismes publics : Conformément à la
                                            réglementation en vigueur, les Données peuvent être transmises aux autorités
                                            compétentes sur requête et notamment aux organismes publics, exclusivement
                                            pour répondre aux obligations légales, les auxiliaires de justice, les officiers
                                            ministériels et les organismes chargés d’effectuer le recouvrement de créances.
                                        </p>

                                        <p className="mb-2">
                                            Accès aux données personnelles par l'Utilisateur
                                        </p>
                                        <p className="mb-2">
                                            Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux
                                            fichiers et aux libertés, toute personne physique dispose du droit d’accès et, le
                                            cas échéant, la rectification ou la suppression des Données la concernant.
                                            L’exercice de ces droits s’effectue à tout moment par voie postale au 5 Bis
                                            Chemin du Prieuré 95380 Epiais Les Louvres, ou électronique : kitoun.cf@gmail.com.
                                        </p>
                                        <p className="mb-2">
                                            L’éditeur rappelle que toute personne physique peut, pour des motifs légitimes,
                                            s’opposer au traitement des Données la concernant.
                                        </p>
                                        <p className="mb-2">
                                            Durée de conservation et archivage des Données
                                        </p>
                                        <p className="mb-2">
                                            Les Données des Utilisateurs ne seront pas conservées au-delà de la durée
                                            strictement nécessaire aux finalités poursuivies telles qu'énoncées au présentes
                                            et ce conformément à la Loi.
                                        </p>
                                        <p className="mb-2">
                                            Sécurité L’éditeur prend des mesures adéquates sur le plan de la technique et de
                                            l'organisation pour interdire l'accès non autorisé ou la divulgation des Données
                                            de l'Utilisateur.
                                        </p>
                                        <p className="mb-2">
                                            Cependant, l’éditeur ne peut garantir l'élimination de tout risque d'utilisation
                                            abusive des Données.
                                        </p>
                                        <p className="mb-2">
                                            Il importe que l'Utilisateur préserve la confidentialité de ses identifiants de
                                            façon à empêcher une utilisation illicite de son compte.
                                        </p>

                                        <p className="mb-2">
                                            <b>Les cookies</b>
                                        </p>
                                        <p className="mb-2">
                                            L’Utilisateur est informé que, lors de ses connexions sur le Site et lors de
                                            l’utilisation des Services, des Cookies sont installés sur son terminal de reception
                                            (ordinateur, tablette, smartphone, etc.) pour le bon fonctionnement des
                                            Services, ou sur son navigateur sous réserve du choix exprimé par l’Utilisateur
                                            concernant les cookies ; ce choix pouvant être modifié à tout moment.
                                        </p>
                                        <p className="mb-2">
                                            Les cookies de navigation sur le site et applications: Plus précisément ces
                                            Cookies de navigation permettent notamment :
                                            <ul>
                                                <li>de mémoriser des informations relatives au formulaire que l’Utilisateur a
                                                    rempli sur le Site ou à des informations que l’Utilisateur a choisi sur le Site ;</li>
                                                <li>De mettre en oeuvre des mesure de sécurité, par exemple lorsqu’il est demandé à
                                                    l’Utilisateur de se connecter à nouveau à un contenu ou à un Service après une
                                                    certaine durée écoulée.</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <Link className="btn-default" to="/"><span>Retour</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Cgv;