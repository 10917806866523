import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Renforcement extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Renforcement</h2>
                                </div>
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>
                                            Dans la joie et la bonne humeur, avec des exercices originaux, remusclez votre corps pour le rendre plus résistant, plus mobile, plus fonctionnel, etc.
                                            Et oubliez vos petites douleurs chroniques.
                                        </p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/bg/bg-image-30.jpg" alt="Blog Images"/>
                                        </div>
                                        <h5 className="title" className="mt--40">Sénior Mouv</h5>
                                        <p>
                                            Renforcement dédié à nos ainés, mais pas que. Nous travaillons la posture, l'équilibre, la coordination au travers de cours variés :
                                            circuits, danse, renfo sur chaise, etc.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-btn mt--30">
                                    <Link className="btn-default" to="/"><span>Retour</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Renforcement;